import { Fragment, useState } from "react"
import styled from "styled-components"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { ShortcodeDialog } from "src/components/Homes/HomeDetails/Shortcodes/ShortcodeDialog"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { IGuestCommunication } from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { MLinkStyle, MLinkStyleProps } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"

interface IRow {
  name: string
  id: keyof IGuestCommunication
  message: string
}

export function ShortcodeTable({
  homeId,
  rowData = [],
}: {
  homeId: string
  rowData: IRow[]
}) {
  const { t } = useTranslate()

  const [shortcodeData, setShortcodeData] = useState<null | IRow>(null)
  const [showUpgradeBlocker, setShowUpgradeBlocker] = useState(false)

  const guestConnectAvailability = useFeatureAvailability({
    feature: Feature.GUEST_CONNECT,
  })

  function getRowDataByIndex(index: number): IRow | undefined {
    return rowData?.[index]
  }

  function handleRowClick(index: number) {
    const row = getRowDataByIndex(index)
    if (!row?.id) {
      return
    }
    setShortcodeData(row)
  }

  function handleEditClick(row: IRow) {
    if (guestConnectAvailability.available) {
      setShortcodeData(row)
    } else {
      setShowUpgradeBlocker(true)
    }
  }

  const header = [
    <div key={"name"}>{t(langKeys.name)}</div>,
    <div key={"message"}>{t(langKeys.message)}</div>,
    <div key={"misc"}>{/* actions */}</div>,
  ]

  const rows = rowData.map((row) => {
    return (
      <Fragment key={row.id}>
        <div>
          <Title>{row.name}</Title>
        </div>
        <div>
          <Ellipsis>{row.message}</Ellipsis>
        </div>
        <div>
          <MoreButton onEdit={() => handleEditClick(row)} />
        </div>
      </Fragment>
    )
  })

  const mobileRows = rowData.map((row) => {
    return (
      <MobileRow key={row.id}>
        <div>
          <Title>{row.name}</Title>
          <Ellipsis>{row.message}</Ellipsis>
        </div>
        <div>
          <MoreButton onEdit={() => handleEditClick(row)} />
        </div>
      </MobileRow>
    )
  })

  return (
    <div>
      <GridTable
        header={header}
        rows={rows}
        templateColumns={`auto minmax(1ch, 1fr) auto`}
        onRowClick={handleRowClick}
        mobileRows={mobileRows}
      />

      <ShortcodeDialog
        open={!!shortcodeData}
        initialData={shortcodeData}
        homeId={homeId}
        onClose={() => setShortcodeData(null)}
      />

      <UpgradeBlockerDialog
        open={showUpgradeBlocker}
        onClose={() => setShowUpgradeBlocker(false)}
      />
    </div>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <MText variant="subtitle">
      <LinkStyle>{children}</LinkStyle>
    </MText>
  )
}

const Ellipsis = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 30ch;
`

const MobileRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LinkStyle = styled.div<MLinkStyleProps>`
  ${MLinkStyle};
`
