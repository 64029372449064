import { useState } from "react"

import { AxiosError } from "axios"

import { DeleteHomeDialog } from "src/components/Homes/HomeDetails/Overview/DeleteHomeDialog"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useDeleteHome } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

enum ErrorKey {
  MEMBERS_IN_HOME = "members_still_in_home",
}

function parseError(request?: { status: number; response: string }): {
  recognized?: boolean
  message: string
} {
  const message = "Oh no! Something went wrong" // default
  switch (request?.status) {
    case 400: {
      const parsed: {
        message: string
        error_key: ErrorKey | string
      } = JSON.parse(request.response)
      switch (parsed.error_key) {
        case ErrorKey.MEMBERS_IN_HOME:
          return {
            message: parsed.message,
            recognized: true,
          }

        default:
          return { message }
      }
    }
    default:
      return { message }
  }
}

export function RemoveHome({
  homeId,
  homeName,
  disabled,
  onRemoveSuccess,
}: {
  homeId: string
  homeName: IHome["name"]
  disabled: boolean
  onRemoveSuccess: () => void
}) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const [errorMsg, setErrorMsg] = useState("")
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)

  const deleteHome = useDeleteHome()

  const homeTokensFeature = useFeatureAvailability({
    feature: "home_tokens",
    refresh: true,
  })

  function onError(error: AxiosError) {
    const { recognized } = parseError(error?.request)
    setErrorMsg(t(langKeys.failed_something_went_wrong))
    if (!recognized) {
      ErrorService.captureException(error)
    }
    setShowConfirmRemove(false)
  }
  function onSuccess() {
    setErrorMsg("")
    setShowConfirmRemove(false)
    onRemoveSuccess()
  }
  async function handleRemove() {
    deleteHome.mutate({ orgId, homeId }, { onSuccess, onError })
  }

  return (
    <div>
      <MText variant="subtitle" marginBottom={spacing.XS}>
        {t(langKeys.home_remove_home)}
      </MText>
      <MText variant="body" color="secondary" marginBottom={spacing.M}>
        {t(langKeys.home_remove_home_confirm_body)}
      </MText>

      {!!errorMsg && <MBanner type="error">{errorMsg}</MBanner>}

      <div>
        <MButtonLegacy
          onClick={() => setShowConfirmRemove(true)}
          disabled={disabled}
          loading={deleteHome.isLoading}
          size="large"
          variant="outlined"
          emergency
        >
          {t(langKeys.home_remove_home)}
        </MButtonLegacy>
      </div>

      {homeTokensFeature.available && showConfirmRemove ? (
        <DeleteHomeDialog
          onClose={() => setShowConfirmRemove(false)}
          onConfirm={handleRemove}
          loading={deleteHome.isLoading}
          homeId={homeId}
          homeName={homeName}
        />
      ) : (
        <ConfirmDialog
          title={`${t(langKeys.home_remove_home)}?`}
          open={showConfirmRemove}
          onClose={() => setShowConfirmRemove(false)}
          loading={deleteHome.isLoading}
          confirmLabel={t(langKeys.home_remove_home)}
          onConfirm={() => handleRemove()}
          description={t(langKeys.home_remove_home_confirm_body)}
          confirmButtonProps={{ color: "destructive" }}
        />
      )}
    </div>
  )
}
