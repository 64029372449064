import styled from "styled-components"

import { RemoveHome } from "src/components/Homes/HomeDetails/Overview/RemoveHome"
import { SettingsView } from "src/components/Settings/SettingsView"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import {
  getHomeSettings,
  IHomeSetting,
} from "src/data/homeSettings/logic/homeSettings"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { useOrgQueryCache } from "src/data/organizations/queries/organizationQueryCache"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeSettings({ home }: { home: IHome }) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()

  const { org, orgId } = useOrganization()
  const { updateCachedHomeDetail } = useOrgQueryCache()
  const homeId = home.home_id

  const fetchProfiles = useFetchProfiles({
    orgId,
  })

  const { ready, value } = useIsHomeGroupAdmin()
  const isHomeGroupAdmin = ready && value
  const profiles = fetchProfiles.data?.profiles || []

  const patchHome = usePatchHome()

  const access = getAccessLogic({ role: org.user_role })

  const canRemoveHome = access.hasAdminAccess || isHomeGroupAdmin

  const homeSettings = getHomeSettings({
    canRemoveHome,
    onSave: async (profileId) => {
      if (!orgId) return
      const profile_id = String(profileId)
      await patchHome.mutateAsync({
        orgId,
        homeId,
        data: { profile_id },
      })
    },
  })

  if (!home) {
    return <div>"Could not find the home.."</div>
  }

  if (!profiles.length) {
    return null
  }

  async function onSubmitSuccess(homeUpdate: IHome) {
    if (!homeUpdate) return
    updateCachedHomeDetail(org.id, homeUpdate.home_id, (cachedHome) => {
      return { ...cachedHome, ...homeUpdate }
    })
  }

  const availableProfiles: IProfileResponse[] = [...profiles]
  const profileSetting: IHomeSetting[] = [{ ...home, availableProfiles }]

  return (
    <HomeSettingsBox>
      <MText variant="heading2">{t(langKeys.settings)}</MText>

      <SettingsView
        content={homeSettings}
        currentSettings={profileSetting}
        title=""
        viewConfig={{ onSubmitSuccess }}
      />

      <RemoveHome
        homeId={home.home_id}
        homeName={home.name}
        disabled={!canRemoveHome}
        onRemoveSuccess={() => navigate(Routes.Homes.location())}
      />
    </HomeSettingsBox>
  )
}

const HomeSettingsBox = styled.div`
  display: grid;
  max-width: 600px;
  grid-gap: ${spacing.M};
`
