import { API_DEFAULT } from "src/constants/minutApi"
import {
  EditType,
  IDropdown,
  TEditField,
  TSaveValue,
} from "src/data/editField/editFieldTypes"
import { IPatchHome } from "src/data/homes/types/homeQueryTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { HomeSettingsProfileDynamic } from "src/data/homeSettings/logic/HomeSettingsJsx"
import { langKeys } from "src/i18n/langKeys"

export type IHomeSetting = IHome & {
  availableProfiles: { name: string; id: string }[]
}
type IHomeSettingPayload = IPatchHome

const HomeProfileField: IDropdown<IHomeSetting, IHomeSettingPayload> = {
  title: langKeys.profile_title,
  info: langKeys.profile_short_description,
  dynamicInfo: HomeSettingsProfileDynamic,
  type: EditType.DROPDOWN,
  options: [],
  id: "home profile dropdown",
  defaultValue: "",
  affectsHomes: true,
  storedValue: (settings: IHomeSetting): string => {
    return settings?.profile_id || ""
  },
  populate: (
    settings: IHomeSetting,
    currentFieldData?: TEditField<IHomeSetting, IHomeSettingPayload>
  ): IDropdown<IHomeSetting, IHomeSettingPayload> => {
    return {
      ...HomeProfileField,
      ...currentFieldData,
      type: EditType.DROPDOWN,
      defaultValue: settings.profile_id || "",
      options: [
        { name: "", value: "", disabled: true, hidden: true },
        ...settings.availableProfiles.map((p) => ({
          name: p.name,
          value: p.id,
        })),
      ],
    }
  },
  payload: (profile_id: string) => ({ profile_id }),
  endpointUrl: (settings: IHomeSetting) =>
    `${API_DEFAULT}/homes/${settings.home_id}`,
  endpointMethod: "PUT",
}

export function getHomeSettings({
  canRemoveHome,
  onSave,
}: {
  canRemoveHome: boolean
  onSave: ((profileId: TSaveValue) => Promise<void>) | undefined
}): TEditField<IHomeSetting, IHomeSettingPayload>[] {
  const homeProfileEditText = !!canRemoveHome
    ? "" /* use default */
    : "Only editable by home owner"
  return [
    {
      ...HomeProfileField,
      disabled: !canRemoveHome,
      editText: homeProfileEditText,
      onSave,
    },
  ]
}
